import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import InstagramIcon from './Icons/InstagramIcon'
import TwitterIcon from './Icons/TwitterIcon'
import FacebookIcon from './Icons/FacebookIcon'
import GithubIcon from './Icons/GithubIcon'
import { cleanPath } from '../utils/path'

import logo from '../img/footer-logo.svg'

const MenuWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
  justify-content: space-between;
  max-width: 900px;
  width: 100%;
  margin: auto;
  padding-bottom: 40px;
  * {
    color: white !important;
  }
  & h5 {
    margin-bottom: 20px;
  }
  & a {
    display: block;
    margin-top: 5px;
    &:hover {
      opacity: 0.7;
    }
  }
  @media(max-width: 1023px) {
    width: 90%;
    margin: auto;
  }
  @media(max-width: 768px) {
    grid-template-columns: repeat(2, auto);
    justify-content: space-between;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    max-width: 350px;
    width: 100%;
    margin: auto;
  }
`
const FooterBottomWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  margin-top: 50px;
  padding-bottom: 40px;
  * {
    color: white;  
  }
  
  & svg {
    margin-left: 20px;
    max-width: 22px;
    max-height: 22px;
    &:hover {
      cursor: pointer;
    }
  }
  @media(max-width: 1023px) {
    width: 90%;
    margin: auto;
  }
  @media(max-width: 768px) {
    grid-template-columns: repeat(1, auto);
    grid-row-gap: 20px;
    max-width: 350px;
    width: 100%;
    margin: auto;
    & div:nth-child(1) {
      text-align: right;
    }
  }
`

const FooterLogo = styled.div`
  width: 38px;
  height: 38px;
  position: absolute;
  bottom: 50px;
  left: calc(50% - 19px);
  @media(max-width: 768px) {
    left: calc(50% - 178px);
    bottom: 82px;
  }
  @media(max-width: 380px) {
    left: calc(50% - 165px);
  }
  @media(max-width: 360px) {
    bottom: 105px;
    left: 10px;
  }
`

const Footer = () => {
  return (
    <StaticQuery
      query ={graphql`
        query FooterQuery {
          allMarkdownRemark (
            filter: { fileAbsolutePath: {regex: "/data/footer/"}}
          ) {
            edges {
              node {
                frontmatter {
                  firstColumn {
                    title
                    list {
                      menuItem
                      link
                    }
                  }
                  secondColumn {
                    title
                    list {
                      menuItem
                      link
                    }
                  }
                  thirdColumn {
                    title
                    list {
                      menuItem
                      link
                    }
                  }
                  forthColumn {
                    title
                    list {
                      menuItem
                      link
                    }
                  }
                  fifthColumn {
                    title
                    list {
                      menuItem
                      link
                    }
                  }
                  socialLink {
                    facebook
                    twitter
                    instagram
                    github
                  }
                }
              }
            }
          }
        }
      `}
      render={data=>(
        <footer className="footer">
          <div className="container">
            <MenuWrap>
              <div> 
                <h5>{data.allMarkdownRemark.edges[0].node.frontmatter.firstColumn.title}</h5>
                {
                  data.allMarkdownRemark.edges[0].node.frontmatter.firstColumn.list.map(item=>(
                    <Link to={cleanPath(item.link)}>{item.menuItem}</Link>
                  ))
                }
              </div>
              <div> 
                <h5>{data.allMarkdownRemark.edges[0].node.frontmatter.secondColumn.title}</h5>
                {
                  data.allMarkdownRemark.edges[0].node.frontmatter.secondColumn.list.map(item=>(
                    <Link to={cleanPath(item.link)}>{item.menuItem}</Link>
                  ))
                }
              </div>
              <div> 
                <h5>{data.allMarkdownRemark.edges[0].node.frontmatter.thirdColumn.title}</h5>
                {
                  data.allMarkdownRemark.edges[0].node.frontmatter.thirdColumn.list.map(item=>(
                    <Link to={cleanPath(item.link)}>{item.menuItem}</Link>
                  ))
                }
              </div>
              <div> 
                <h5>{data.allMarkdownRemark.edges[0].node.frontmatter.forthColumn.title}</h5>
                {
                  data.allMarkdownRemark.edges[0].node.frontmatter.forthColumn.list.map(item=>(
                    <Link to={cleanPath(item.link)}>{item.menuItem}</Link>
                  ))
                }
              </div>
              <div> 
                <h5>{data.allMarkdownRemark.edges[0].node.frontmatter.fifthColumn.title}</h5>
                {
                  data.allMarkdownRemark.edges[0].node.frontmatter.fifthColumn.list.map(item=>(
                    <Link to={cleanPath(item.link)}>{item.menuItem}</Link>
                  ))
                }
              </div>
            </MenuWrap>
            <FooterBottomWrap>
              <div>
                <a href={data.allMarkdownRemark.edges[0].node.frontmatter.socialLink.twitter} target="_blank" rel="noreferrer">
                  <TwitterIcon />
                </a>
               
                <a href={data.allMarkdownRemark.edges[0].node.frontmatter.socialLink.facebook} target="_blank" rel="noreferrer">
                  <FacebookIcon />
                </a>
                <a href={data.allMarkdownRemark.edges[0].node.frontmatter.socialLink.github} target="_blank" rel="noreferrer">
                  <GithubIcon />
                </a>
                <a href={data.allMarkdownRemark.edges[0].node.frontmatter.socialLink.instagram} target="_blank" rel="noreferrer">
                  <InstagramIcon />
                </a>
              </div>

              <div>
                © Biometric Vision 2020. All rights reserved
              </div>
            </FooterBottomWrap>
            <FooterLogo>
                <img src={logo} alt="biometrics-vision" />
            </FooterLogo>
          </div>
        </footer>
      )}
    />
  )
}

export default Footer
