import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import logo from '../img/logo.svg'
import styled from 'styled-components'
import activeLogo from '../img/activeLogo.svg'
import MenuArrow from './Icons/MenuArrow'
import { cleanPath } from '../utils/path'
import { v4 } from 'uuid'

const DropDownMenu = styled.div`
  display: initial;
  width: 100%;
  margin-top: 20px;
  & svg {
    float: right;
    margin-right: 10px;
    margin-top: 3px;
    transition: transform ease 0.1s;
    transform: ${props=>props.ts};
  }
`
const DropDownWrap = styled.div`
  background: #F4F4F4;
  display: ${props=>props.display};
  @media(max-width: 1200px) {
    margin-left: 10px;
    background: rgba(255, 255, 255, 0.001);
  }
`

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
      navBarActiveWhite: '',
      isVisble: false,
      isUsecaseVisible: false,
    }
    this.handleProductDropDown = this.handleProductDropDown.bind(this)
    this.handleUsecaseDropDown = this.handleUsecaseDropDown.bind(this)
  }

  handleProductDropDown() {
    let flag = this.state.isVisble
    this.setState({
      isVisble: !flag
    })
  }
  handleUsecaseDropDown() {
    let flag = this.state.isUsecaseVisible
    this.setState({
      isUsecaseVisible: !flag
    })
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
        isVisble: false,
        isUsecaseVisible: false,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
              mobilNavBarActiveClass: 'is-active-mobile',
              navBarActiveWhite: 'is-white-navbar'
            })
          : this.setState({
              navBarActiveClass: '',
              navBarActiveWhite: '',
              mobilNavBarActiveClass: 'is-disable-mobile',
            })
      }
    )
  }

  render() {
    const { isVisble, isUsecaseVisible } = this.state
    return (
      <StaticQuery
        query ={graphql`
          query HeaderDataQuery {
            allMarkdownRemark (
              filter: { fileAbsolutePath: {regex: "/data/header/"}}
            ) {
              edges {
                node {
                  frontmatter {
                    productsMenu {
                      name
                      dropdown {
                        menuItem
                        link
                      }
                    }
                    useCasesMenu {
                      name
                      dropdown {
                        menuItem
                        link
                      }
                    }
                    pricingMenu {
                      name
                      link
                    }
                    developersMenu {
                      name
                      link
                    }
                    partnersMenu {
                      name
                      link
                    }
                    companyMenu {
                      name
                      link
                    }
                    loginbutton {
                      text
                      link
                      linkNewWindow
                    }
                    startButton {
                      text
                      link
                      linkNewWindow
                    }
                  }
                }
              }
            }
          }
        `}
        render={data=>(
          <div className= {`is-sticky-bar`}>
            <nav
              className={`navbar is-transparent ${this.state.navBarActiveWhite}`}
              id="navbar"
              role="navigation"
              aria-label="main-navigation"
            >
              <div className="container">
                <div className="navbar-brand">
                  <Link to="/" className="is-tablet-show navbar-item logo" title="Logo">
                    <img src={logo} alt="biometrics-vision" />
                  </Link>
                  <Link to="/" className="is-tablet-hidden navbar-item logo " title="Logo">
                    <img src={activeLogo} alt="biometrics-vision" />
                  </Link>
                  {/* Hamburger menu */}
                  <div
                    className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                    data-target="navMenu"
                    onClick={() => this.toggleHamburger()}
                  >
                    <span />
                    <span />
                    <span />
                  </div>
                </div>
                <div
                  id="navMenu"
                  className="is-navbar-mobile-hidden  navbar-menu"
                >
                  <div className="navbar-start has-text-centered ">
                    <div className="navbar-item has-dropdown is-hoverable">
                      <a className="navbar-link">
                        {data.allMarkdownRemark.edges[0].node.frontmatter.productsMenu.name}
                      </a>
                      <div className="navbar-dropdown">
                        {
                          data.allMarkdownRemark.edges[0].node.frontmatter.productsMenu.dropdown.map(item=>(
                            <Link key={v4()} className="navbar-item" to={cleanPath(item.link)}>
                              {item.menuItem}
                            </Link>
                          ))
                        }
                      </div>
                    </div>
                    <div className="navbar-item has-dropdown is-hoverable">
                      <a className="navbar-link">
                        {data.allMarkdownRemark.edges[0].node.frontmatter.useCasesMenu.name}
                      </a>
                      <div className="navbar-dropdown">
                        {
                          data.allMarkdownRemark.edges[0].node.frontmatter.useCasesMenu.dropdown.map(item=>(
                            <Link key={v4()} className="navbar-item" to={cleanPath(item.link)}>
                              {item.menuItem}
                            </Link>
                          ))
                        }
                      </div>
                    </div>
                    <Link className="navbar-item" to={cleanPath(data.allMarkdownRemark.edges[0].node.frontmatter.pricingMenu.link)}>
                      {data.allMarkdownRemark.edges[0].node.frontmatter.pricingMenu.name}
                    </Link>
                    <Link className="navbar-item" to={cleanPath(data.allMarkdownRemark.edges[0].node.frontmatter.developersMenu.link)}>
                      {data.allMarkdownRemark.edges[0].node.frontmatter.developersMenu.name}
                    </Link>
                    <Link className="navbar-item" to={cleanPath(data.allMarkdownRemark.edges[0].node.frontmatter.partnersMenu.link)}>
                      {data.allMarkdownRemark.edges[0].node.frontmatter.partnersMenu.name}
                    </Link>
                    <Link className="navbar-item" to={cleanPath(data.allMarkdownRemark.edges[0].node.frontmatter.companyMenu.link)}>
                      {data.allMarkdownRemark.edges[0].node.frontmatter.companyMenu.name}
                    </Link>
                  </div>
                  
                  <div className="navbar-end has-text-centered">
                    {data.allMarkdownRemark.edges[0].node.frontmatter.loginbutton.linkNewWindow?(
                      <a
                        className="navbar-item"
                        href={cleanPath(data.allMarkdownRemark.edges[0].node.frontmatter.loginbutton.link)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="nav-button is-second">
                          {data.allMarkdownRemark.edges[0].node.frontmatter.loginbutton.text}
                        </button>
                      </a>
                    ):(
                      <Link
                        className="navbar-item"
                        to={cleanPath(data.allMarkdownRemark.edges[0].node.frontmatter.loginbutton.link)}
                      >
                        <button className="nav-button is-second">
                          {data.allMarkdownRemark.edges[0].node.frontmatter.loginbutton.text}
                        </button>
                      </Link>
                    )}

                    {data.allMarkdownRemark.edges[0].node.frontmatter.startButton.linkNewWindow?(
                      <a
                        className="navbar-item"
                        href={cleanPath(data.allMarkdownRemark.edges[0].node.frontmatter.startButton.link)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="nav-button is-primary">
                          {data.allMarkdownRemark.edges[0].node.frontmatter.startButton.text}
                        </button>
                      </a>
                    ):(
                      <Link
                        className="navbar-item"
                        to={cleanPath(data.allMarkdownRemark.edges[0].node.frontmatter.startButton.link)}
                      >
                        <button className="nav-button is-primary">
                          {data.allMarkdownRemark.edges[0].node.frontmatter.startButton.text}
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div className="container is-mobile-show-container">
                <div className={`is-mobile-show navbar-start has-text-centered ${this.state.mobilNavBarActiveClass}`}>
                  <div className="navbar-item has-dropdown is-hoverable">
                    <DropDownMenu 
                      className="navbar-item" 
                      onClick={() => this.handleProductDropDown()}
                      ts={isVisble?"rotate(0)": "rotate(180deg)"}
                    >
                      {data.allMarkdownRemark.edges[0].node.frontmatter.productsMenu.name} <MenuArrow />
                    </DropDownMenu>
                    <DropDownWrap display={isVisble?"block":"none"}>
                      {
                        data.allMarkdownRemark.edges[0].node.frontmatter.productsMenu.dropdown.map(item=>(
                          <Link key={v4()} className="navbar-item" to={cleanPath(item.link)}>
                            {item.menuItem}
                          </Link>
                        ))
                      }
                    </DropDownWrap>
                  </div>
                  <div className="navbar-item has-dropdown is-hoverable">
                    <DropDownMenu 
                      className="navbar-item" 
                      onClick={() => this.handleUsecaseDropDown()}
                      ts={isUsecaseVisible?"rotate(0)": "rotate(180deg)"}
                    >
                      {data.allMarkdownRemark.edges[0].node.frontmatter.useCasesMenu.name} <MenuArrow />
                    </DropDownMenu>
                    <DropDownWrap display={isUsecaseVisible?"block":"none"}>
                      {
                        data.allMarkdownRemark.edges[0].node.frontmatter.useCasesMenu.dropdown.map(item=>(
                          <Link key={v4()} className="navbar-item" to={cleanPath(item.link)}>
                            {item.menuItem}
                          </Link>
                        ))
                      }
                    </DropDownWrap>
                  </div>
                  <Link className="navbar-item" to={cleanPath(data.allMarkdownRemark.edges[0].node.frontmatter.pricingMenu.link)}>
                    {data.allMarkdownRemark.edges[0].node.frontmatter.pricingMenu.name}
                  </Link>
                  <Link className="navbar-item" to={cleanPath(data.allMarkdownRemark.edges[0].node.frontmatter.developersMenu.link)}>
                    {data.allMarkdownRemark.edges[0].node.frontmatter.developersMenu.name}
                  </Link>
                  <Link className="navbar-item" to={cleanPath(data.allMarkdownRemark.edges[0].node.frontmatter.partnersMenu.link)}>
                    {data.allMarkdownRemark.edges[0].node.frontmatter.partnersMenu.name}
                  </Link>
                  <Link className="navbar-item" to={cleanPath(data.allMarkdownRemark.edges[0].node.frontmatter.companyMenu.link)}>
                    {data.allMarkdownRemark.edges[0].node.frontmatter.companyMenu.name}
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        )}
      />
    )
  }
}

export default Navbar
